import React from "react"

import { PageProps } from "gatsby"

import AdminTripDetails from "components/admin-trip-details"
import Container from "components/container"
import { DriveLayout } from "components/layout-custom"

const Page = (props: PageProps) => {
  const tripId = props.params.tripId

  return (
    <DriveLayout title="Trip Details" padded={true}>
      <Container>
        <AdminTripDetails tripId={parseInt(tripId)} allowAdminActions={false} />
      </Container>
    </DriveLayout>
  )
}

export default Page
